const PrivacyPolicy = () => {
  return (
    <div>
      <iframe
        src={"/PrivacyPolicy.pdf"}
        width="100%"
        height="1000px"
        title="Privacy Policy"
      />
    </div>
  );
};

export default PrivacyPolicy;
